import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import posthog from 'posthog-js';

import { client } from 'client/services.gen';

const baseURL = import.meta.env.VITE_APP_BASE_URL || '';
const [, domain = ''] = baseURL.split('://');
const [host] = domain.split(':');

const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
const environment = import.meta.env.VITE_APP_SENTRY_ENV;
const sentryOrg = import.meta.env.VITE_APP_SENTRY_ORG;
const sentryProjectId = import.meta.env.VITE_APP_SENTRY_PROJECT_ID;
const posthogApiHost = import.meta.env.VITE_APP_POSTHOG_API_HOST;
const posthogToken = import.meta.env.VITE_APP_POSTHOG_TOKEN;

// use fake token for local development to enable bootstrap options
posthog.init(
  posthogToken || (environment === 'development' ? environment : ''),
  {
    api_host: posthogApiHost,
    bootstrap: {
      featureFlags: {
        // project-sample: true,
      },
    },
  },
);

if (dsn) {
  Sentry.init({
    dsn,
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [host || window.location.origin],
      }),
      posthog.sentryIntegration({
        organization: sentryOrg,
        projectId: Number.parseInt(sentryProjectId!, 10),
      }),
    ],
    replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0,
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0,
    tracesSampleRate: 0.3,
  });

  client.instance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === 405) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  });
}
