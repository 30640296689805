import localforage from 'localforage';
import type { AtomEffect } from 'recoil';
import { DefaultValue } from 'recoil';

import type { ClientState } from 'types/recoil';

export const atomEffectLocalStorage =
  <T extends ClientState[keyof ClientState]>(
    callback?: (param: Parameters<AtomEffect<T>>[0], value: T | null) => void,
  ): AtomEffect<T> =>
  (param) => {
    const { node, onSet, setSelf } = param;

    localforage.getItem<T>(node.key).then((value) => {
      // Execute side effects first.
      if (callback) {
        callback(param, value);
      }

      if (value !== null) {
        setSelf(value);
      }
    });

    onSet((value) => {
      try {
        if (value instanceof DefaultValue) {
          localforage.removeItem(node.key);
        } else {
          localforage.setItem(node.key, value);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  };
