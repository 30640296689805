import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

import type { GetPeopleCurrentResponse } from 'client/types.gen';
import { getPersonName } from 'helpers/person';

export * from './api';
export * from './elasticsearch';
export * from './posthog';

const posthogToken = import.meta.env.VITE_APP_POSTHOG_TOKEN;

export const setUser = (user?: GetPeopleCurrentResponse) => {
  if (user) {
    // currently, while the schema suggests that there can be multiple
    // companies, only one is assumed to be returned
    const company =
      user.roles && user.roles.length > 0 ? user.roles[0].company : null;

    if (posthogToken) {
      posthog.identify(user.uuid, {
        company_id: company?.id,
        company_name: company?.name,
        company_type: company?.type,
        email: user.email,
        name: getPersonName(user),
      });
      if (company) {
        posthog.group('company', company?.id.toString(), {
          name: company?.name,
        });
      }
    }

    const scope = Sentry.getCurrentScope();
    scope.setUser({
      email: user.email,
      id: user.uuid,
      username: getPersonName(user),
    });
  } else {
    if (posthogToken) {
      posthog.reset(true);
    }

    const scope = Sentry.getCurrentScope();
    scope.setUser(null);
  }
};
