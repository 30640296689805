import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import type { InternalAxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios';
import localforage from 'localforage';
import queryString from 'query-string';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import type { AccessToken } from './client';
import { routeTree } from './routeTree.gen';

import { client, postToken } from 'client/services.gen';
import { AppProvider } from 'components/AppProvider';
import { AxiosAuthSubscriber } from 'components/AxiosAuthSubscriber';
import * as serviceWorker from 'config/serviceWorker';
import { isAxiosErrorExpiredAccessToken } from 'helpers/api';
import { queryClient } from 'helpers/query';
import { generateSearch } from 'helpers/router';

// CSS reset, needed to hide some buttons for example
// learn more: https://github.com/ant-design/ant-design/issues/38732
import 'antd/dist/reset.css';

import 'config/i18n';
import 'config/logging';
import 'config/yup';

import 'config/styles.css';
import 'config/tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container!);

client.setConfig({
  baseURL: import.meta.env.VITE_APP_BASE_URL || '',
  paramsSerializer: (params) => queryString.stringify(params),
});

client.instance.interceptors.request.use(async (request) => {
  const token = await localforage.getItem<AccessToken>('atomToken');
  if (token) {
    request.headers.Authorization = `Bearer ${token.access_token}`;
  }
  return request;
});

// Intercept image requests and modify the response type. We need to do
// this because the Ant Design Avatar component expects a URL string to
// load an image. Since API returns files, we use `URL.createObjectURL()`
// to generate URLs on the fly. This method accepts blobs, so we need to
// tell Axios to load the response as a blob before it can be processed.
client.instance.interceptors.request.use((request) => {
  if (request.method === 'get' && request.url?.includes('/image')) {
    request.responseType = 'blob';
  }
  return request;
});

client.instance.interceptors.response.use(async (response) => {
  if (response.data && typeof response.data === 'object') {
    const token = response.data as AccessToken;
    // check if response has token shape
    if (
      token.token_type === 'bearer' &&
      token.access_token &&
      token.refresh_token
    ) {
      await localforage.setItem('atomToken', token);
      queryClient.invalidateQueries();
      router.invalidate();
    }
  }
  return response;
});

let isRefreshingToken = false;
let retryQueue: Array<{
  reject: (error: unknown) => void;
  resolve: (value: unknown) => void;
}> = [];

client.instance.interceptors.response.use(undefined, async (error) => {
  const token = await localforage.getItem<AccessToken>('atomToken');
  // Retry only requests with expired token that have not been retried yet.
  if (!isAxiosErrorExpiredAccessToken(error) || !token) {
    return Promise.reject(error);
  }

  if (!(error instanceof AxiosError) || !error.config) {
    return Promise.reject(error);
  }

  /**
   * retry pattern adapted from StackOverflow
   * @link https://stackoverflow.com/a/58395785
   */
  const originalRequest = error.config as InternalAxiosRequestConfig & {
    _retry?: boolean;
  };

  if (originalRequest._retry) {
    return Promise.reject(error);
  }

  // Set flag so we always send only 1 retry request, even if multiple
  // concurrent requests are failing.
  if (!isRefreshingToken) {
    originalRequest._retry = true;
    isRefreshingToken = true;

    const tryRefreshToken = async () => {
      try {
        await postToken({
          body: {
            expired_token: token.access_token,
            grant_type: 'refresh_token',
            refresh_token: token.refresh_token,
          },
          throwOnError: true,
        });
        retryQueue.forEach(({ resolve }) => resolve(undefined));
        retryQueue = [];
        isRefreshingToken = false;
      } catch (err) {
        retryQueue.forEach(({ reject }) => reject(err));
        retryQueue = [];
        isRefreshingToken = false;
      }
    };

    tryRefreshToken();
  }

  // All failed requests are caught here and retried
  // after we successfully updated the token.
  return new Promise((resolve, reject) => {
    retryQueue = [...retryQueue, { reject, resolve }];
  })
    .then(() => client.instance(originalRequest))
    .catch((err) => Promise.reject(err));
});

const router = createRouter({
  context: {
    queryClient,
    user: undefined,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  notFoundMode: 'fuzzy',
  parseSearch: (query) => queryString.parse(query),
  routeTree,
  stringifySearch: (search) =>
    generateSearch(search, {
      arrayFormat: 'none',
    }),
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

root.render(
  <StrictMode>
    <AppProvider>
      <ReactQueryDevtools buttonPosition="bottom-right" position="bottom" />
      <AxiosAuthSubscriber />
      <RouterProvider router={router} />
    </AppProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
