import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

export const Route = createFileRoute('/_layoutAuth/maintenance/')({
  beforeLoad: () => {
    if (!posthog.isFeatureEnabled('maintenance')) {
      throw redirect({
        replace: true,
        to: '/',
      });
    }
  },
});
