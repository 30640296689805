import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

// TODO: when we can remove tab from the index route or be more specific,
// enable this
// const pipelinesSchema = z.object({
//   tab: z.enum(['table', 'submissions']).optional().catch(undefined),
// });

const pipelinesSchema = z.object({
  tab: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_private/_route/_internal/pipelines')({
  beforeLoad: ({ search }) => {
    if (search.tab === 'submissions') {
      throw redirect({
        replace: true,
        to: '/pipelines/submissions',
      });
    }

    // If tab has any other value, just go to index.
    if (search.tab) {
      throw redirect({
        replace: true,
        search: (search) => ({
          ...search,
          tab: undefined,
        }),
        to: '/pipelines',
      });
    }
  },
  validateSearch: pipelinesSchema,
});
