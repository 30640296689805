/* eslint-disable prefer-object-spread */
const buttonsBareBase = {
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  padding: 0,
  transition: 'all .3s cubic-bezier(.645, .045, .355, 1)',
};

const buttonsTableCellBase = {
  ...buttonsBareBase,
  '&.ant-btn': {
    alignItems: 'baseline',
    display: 'flex',
    hyphens: 'auto',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  ':disabled, :hover:disabled': {
    backgroundColor: 'transparent',
    color: 'gray-6',
  },
  '> span[role="img"]': {
    position: 'relative',
    top: 'grid-2',
  },
  color: 'gray-7',
  height: 'auto',
};

const colors = {
  'avatar-green': '#d3d8cd',
  'blue-1': '#e9f5f5',
  'blue-10': '#000c0d',
  'blue-100': '#b2f1f8',
  'blue-2': '#72DCE8',
  'blue-200': '#72dce8',
  'blue-3': '#9dd8d3',
  'blue-300': '#3dd2e2',
  'blue-4': '#60c2bf',
  'blue-400': '#2abccc',
  'blue-5': '#38a8a8',
  'blue-500': '#18a2b1',
  'blue-6': '#068186',
  'blue-600': '#0e8896',
  'blue-7': '#005a61',
  'blue-700': '#096e79',
  'blue-8': '#00353b',
  'blue-800': '#044e56',
  'blue-9': '#001214',
  'blue-900': '#00353b',
  'focus-primary-button': '#e2f1de',
  'focus-secondary-button': '#cad1d1',
  'geekblue-1': '#e6eef5',
  'geekblue-10': '#00071c',
  'geekblue-2': '#bad2e8',
  'geekblue-3': '#8cb4db',
  'geekblue-4': '#6395cf',
  'geekblue-5': '#3e77c2',
  'geekblue-6': '#1e5bb6',
  'geekblue-7': '#103e8f',
  'geekblue-8': '#062769',
  'geekblue-9': '#011442',
  'gold-1': '#fffbe6',
  'gold-10': '#613400',
  'gold-2': '#fff1b8',
  'gold-3': '#ffe58f',
  'gold-4': '#ffd666',
  'gold-5': '#ffc53d',
  'gold-6': '#faad14',
  'gold-7': '#d48806',
  'gold-8': '#ad6800',
  'gold-9': '#874d00',
  'gray-0': '#F4F4F4',
  'gray-000': '#FAFAFA',
  'gray-1': '#fff',
  'gray-10': '#121211',
  'gray-100': '#F7F7F5',
  'gray-2': '#fafafa',
  'gray-200': '#EFF0EA',
  'gray-3': '#f5f5f5',
  'gray-300': '#E0E1DA',
  'gray-4': '#e8e8e8',
  'gray-400': '#C0C1B8',
  'gray-5': '#d9d9d9',
  'gray-500': '#94958D',
  'gray-6': '#bfbfbf',
  'gray-600': '#61615D',
  'gray-7': '#8c8c8c',
  'gray-700': '#3C3D39',
  'gray-8': '#595959',
  'gray-800': '#1E1E1D',
  'gray-9': '#262626',
  'gray-900': '#121211',
  'green-1': '#f6ffed',
  'green-100': '#ecffdd',
  'green-2': '##d9f7be',
  'green-200': '#d7ffb8',
  'green-3': '#b7eb8f',
  'green-300': '#bef892',
  'green-4': '#95de64',
  'green-400': '#aaea7a',
  'green-5': '#73d13d',
  'green-500': '#94d95f',
  'green-6': '#52c41a',
  'green-600': '#7ab34e',
  'green-7': '#389e0d',
  'green-8': '#237804',
  'green-9': '#135200',
  'lime-1': '#f8ffef',
  'lime-10': '#162110',
  'lime-2': '#f0f9e3',
  'lime-3': '#dfeccf',
  'lime-4': '#cde3b6',
  'lime-5': '#afd68a',
  'lime-6': '#88b068',
  'lime-7': '#6a8a4a',
  'lime-8': '#446331',
  'lime-9': '#293d1d',
  'red-000': '#fff5f5',
  'red-1': '#FFF5F5',
  'red-100': '#ffe3e3',
  'red-2': '#FFC9C9',
  'red-200': '#ffc9c9',
  'red-3': '#FFA8A8',
  'red-300': '#ffa8a8',
  'red-4': '#FF8787',
  'red-400': '#ff8787',
  'red-5': '#FF6B6B',
  'red-500': '#ff6b6b',
  'red-6': '#FA5252',
  'red-600': '#fa5252',
  'red-7': '#DA3434',
  'red-700': '#da3434',
  'red-8': '#692525',
  'red-800': '#692525',
  'red-9': '#381D1D',
  'red-900': '#381d1d',
  'volcano-1': '#fff0e6',
  'volcano-10': '#520800',
  'volcano-2': '#ffcbad',
  'volcano-3': '#ffad85',
  'volcano-4': '#ff8d5c',
  'volcano-5': '#f76631',
  'volcano-6': '#ea400a',
  'volcano-7': '#c42700',
  'volcano-8': '#9e1a00',
  'volcano-9': '#781000',
  'yellow-100': '#fef3de',
  'yellow-200': '#fdedce',
  'yellow-300': '#fce6ba',
  'yellow-400': '#ffda91',
  'yellow-500': '#ffce6d',
  'yellow-600': '#f8b941',
  'yellow-700': '#eaa319',
  'yellow-800': '#bb7f0c',
  'yellow-900': '#7d5200',
};

// Define an additional key for queries instead of using the breakpoints
// array to prevent accidentally breaking styles when we add a new key.
// learn more: https://github.com/system-ui/theme-ui/issues/333
const queries = {
  lg: '1040',
  // Ant Design fixed value, cannot be overridden
  // https://github.com/ant-design/ant-design/blob/master/components/layout/Sider.tsx#L16
  menuCollapsed: '992',
  panelCollapsed: '960',
  sm: '600',
  stackCompact: '680',
  stackExpanded: '1240',
  topBarExpanded: '800',
  xl: '1200',
  xs: '480',
  xxl: '1600',
} as const;

export const theme = {
  buttons: {
    action: {
      ...buttonsBareBase,
      ':active': {
        color: 'ba-green-7',
      },
      ':disabled': {
        backgroundColor: 'transparent',
        color: 'gray-6',
        cursor: 'not-allowed',
      },
      ':focus:not(:active), :hover:not(:active)': {
        backgroundColor: 'transparent',
      },
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        color: 'ba-green-5',
      },
      ':not(:disabled)': {
        cursor: 'pointer',
      },
      color: 'ba-green-6',
      outline: 'none',
    },
    bare: {
      ...buttonsBareBase,
      ':disabled': {
        cursor: 'not-allowed',
      },
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        backgroundColor: 'transparent',
      },
      ':not(:disabled)': {
        cursor: 'pointer',
      },
    },
    fill: {
      ':active': {
        backgroundColor: 'ba-green-2',
        borderColor: 'ba-green-7',
        color: 'ba-green-8',
      },
      ':disabled, :hover:disabled': {
        backgroundColor: 'gray-1',
        borderColor: 'gray-5',
        color: 'gray-6',
      },
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        backgroundColor: 'ba-green-1',
        borderColor: 'ba-green-5',
        color: 'ba-green-6',
      },
      backgroundColor: 'ba-green-1',
      borderColor: 'ba-green-6',
      color: 'ba-green-7',
    },
    filter: {
      '&.ant-btn-lg': {
        borderRadius: '20px',
      },
      ':active': {
        backgroundColor: 'white',
        borderColor: 'gray-800',
        color: 'gray-700',
      },
      ':disabled': {
        backgroundColor: 'gray-400',
        border: 'none',
        color: 'gray-600',
      },
      ':focus:not(:active)': {
        outline: '2px solid',
        outlineColor: 'focus-secondary-button',
        outlineOffset: '0px',
      },
      ':hover:not(:disabled):not(:active)': {
        backgroundColor: 'gray-200',
        borderColor: 'inherit',
        color: 'gray-800',
      },
      backgroundColor: 'white',
      borderColor: 'gray-400',
      color: 'gray-800',
    },
    primary: {
      '&.ant-btn-lg': {
        borderRadius: '20px',
      },
      ':active': {
        backgroundColor: 'gray-800',
        color: 'gray-200',
      },
      ':disabled, :hover:disabled': {
        backgroundColor: 'gray-400',
        border: 'none',
        color: 'gray-600',
      },
      ':focus:not(:active)': {
        border: '1px solid',
        borderColor: 'green-400',
        outline: '2px solid',
        outlineColor: 'focus-primary-button',
        outlineOffset: '0px',
      },
      ':hover:not(:disabled):not(:active)': {
        backgroundColor: 'gray-900',
      },
      backgroundColor: 'gray-800',
      borderRadius: '16px',
      color: 'gray-200',
    },
    secondary: {
      '&.ant-btn-lg': {
        borderRadius: '20px',
      },
      ':active': {
        backgroundColor: 'white',
        borderColor: 'gray-800',
        color: 'gray-700',
      },
      ':disabled': {
        backgroundColor: 'gray-400',
        border: 'none',
        color: 'gray-600',
      },
      ':focus:not(:active)': {
        outline: '2px solid',
        outlineColor: 'focus-secondary-button',
        outlineOffset: '0px',
      },
      ':hover:not(:disabled):not(:active)': {
        backgroundColor: 'gray-200',
        borderColor: 'inherit',
        color: 'gray-800',
      },
      backgroundColor: 'white',
      borderColor: 'gray-400',
      borderRadius: '16px',
      color: 'gray-800',
    },
    tableCellInfo: {
      ...buttonsTableCellBase,
      ':active': {
        backgroundColor: 'transparent',
        color: 'gold-7',
      },
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        backgroundColor: 'transparent',
        color: 'gold-6',
      },
      ':not(:active) > span[role="img"]': {
        color: 'gold-6',
      },
    },
    tableCellLoading: {
      ...buttonsTableCellBase,
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        backgroundColor: 'transparent',
        color: 'gray-7',
      },
    },
    tableCellWarn: {
      ...buttonsTableCellBase,
      ':active': {
        backgroundColor: 'transparent',
        color: 'red-7',
      },
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        backgroundColor: 'transparent',
        color: 'red-5',
      },
      ':not(:active) > span[role="img"]': {
        color: 'red-5',
      },
    },
    tableCellYes: {
      ...buttonsTableCellBase,
      ':active': {
        backgroundColor: 'transparent',
        color: 'ba-green-7',
      },
      ':focus:not(:active), :hover:not(:disabled):not(:active)': {
        backgroundColor: 'transparent',
        color: 'ba-green-6',
      },
      ':not(:active) > span[role="img"]': {
        color: 'ba-green-6',
      },
    },
  },
  colors: {
    ...colors,
    'ba-green-1': colors['blue-1'],
    'ba-green-10': colors['blue-10'],
    'ba-green-2': colors['blue-2'],
    'ba-green-3': colors['blue-3'],
    'ba-green-4': colors['blue-4'],
    'ba-green-5': colors['blue-5'],
    'ba-green-6': colors['blue-6'],
    'ba-green-7': colors['blue-7'],
    'ba-green-8': colors['blue-8'],
    'ba-green-9': colors['blue-9'],
    primary: colors['blue-6'],
  },
  fontSizes: {
    form: {
      large: {
        input: '14px',
      },
    },
  },
  fontWeights: {
    menu: {
      item: 600,
      itemSelected: 600,
    },
  },
  fonts: {
    default:
      "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  mediaQueries: queries,
  radii: {
    circle: '50%',
    'r-2': '2px',
    'r-4': '4px',
  },
  shadows: {
    'elevation-1': '0px 2px 8px rgba(0, 0, 0, .15)',
    'elevation-2': '0px 4px 12px rgba(0, 0, 0, .15)',
    'elevation-4':
      '0px 7px 8px rgba(0, 0, 0, 0.05), 0px 5px 22px rgba(0, 0, 0, 0.03), 0px 12px 17px rgba(0, 0, 0, 0.04);',
    'elevation-5':
      '0px 11px 15px rgba(0, 0, 0, 0.05), 0px 9px 46px rgba(0, 0, 0, 0.03), 0px 24px 38px rgba(0, 0, 0, 0.04);',
    'ghost-button': '0px 0px 2px rgba(0, 0, 0, .0015)',
  },
  sizes: {
    anchorNav: {
      width: '200px',
    },
    form: {
      large: {
        label: {
          height: '22px',
        },
      },
      layout: {
        collapsedWidth: 'calc(100% - 56px)', // collapsed menu width
        width: 'calc(100% - 200px)', // menu width
      },
    },
    header: {
      height: '48px',
    },
    menu: {
      collapsedWidth: '56px',
    },
  },
  space: {
    'grid-0': '0px',
    'grid-10': '10px',
    'grid-12': '12px',
    'grid-14': '14px',
    'grid-16': '16px',
    'grid-18': '18px',
    'grid-2': '2px',
    'grid-20': '20px',
    'grid-22': '22px',
    'grid-24': '24px',
    'grid-26': '26px',
    'grid-28': '28px',
    'grid-30': '30px',
    'grid-32': '32px',
    'grid-34': '34px',
    'grid-36': '36px',
    'grid-38': '38px',
    'grid-4': '4px',
    'grid-40': '40px',
    'grid-42': '42px',
    'grid-44': '44px',
    'grid-46': '46px',
    'grid-48': '48px',
    'grid-56': '56px',
    'grid-6': '6px',
    'grid-60': '60px',
    'grid-72': '72px',
    'grid-78': '78px',
    'grid-8': '8px',
    'grid-80': '80px',
  },
  textStyles: {
    'medium-10/140%': {
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: '140%',
    },
    'medium-12/100%': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '100%',
    },
    'medium-12/140%': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '140%',
    },
    'medium-12/22': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '22px',
    },
    'medium-14/100%': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '100%',
    },
    'medium-14/140%': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '140%',
    },
    'medium-14/22': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
    },
    'medium-16/100%': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '100%',
    },
    'medium-16/120%': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
    },
    'medium-16/140%': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '140%',
    },
    'medium-16/22': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
    },
    'medium-18/100%': {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '100%',
    },
    'medium-20/140%': {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '140%',
    },
    'medium-22/100%': {
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '100%',
    },
    'regular-12/20': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    'regular-14/22': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    'regular-16/24': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    'regular-18/24': {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    'regular-20/28': {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    'regular-24/32': {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    'regular-30/38': {
      fontSize: '30px',
      fontWeight: 400,
      lineHeight: '38px',
    },
    'semibold-10/18': {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '18px',
    },
    'semibold-12/100%': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '100%',
    },
    'semibold-12/18': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '18px',
    },
    'semibold-12/20': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    'semibold-14/22': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '22px',
    },
    'semibold-16/20': {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    'semibold-16/24': {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  zIndices: {
    clientBanner: 1,
    collapsiblePanel: 2,
    content: 0,
    formLayout: 4,
    header: 3,
    modal: 1000, // default Ant value
    popover: 999, // below modal to hide popover if it opens a modal
    sideBar: 100,
    topBar: 5, // above formLayout to hide box-shadow
  },
  // using "as const" gives us this theme type as a read-only object literal, meaning
  // derived types like Theme["mediaQueries"] will actually work!
} as const;
