import { createFileRoute, redirect } from '@tanstack/react-router';

import { getSubmissionMetaOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute(
  '/_private/_route/_internal/pipeline-submissions/$submissionId/',
)({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;
    const pMeta = queryClient.ensureQueryData(
      getSubmissionMetaOptions({
        path: {
          submission_uuid: params.submissionId,
        },
      }),
    );
    const [meta] = await Promise.all([pMeta]);
    throw redirect({
      params: {
        pipelineId: meta.pipeline_slug ?? '',
        submissionId: params.submissionId,
        teamId: meta.team_slug ?? '',
      },
      replace: true,
      to: '/teams/$teamId/pipelines/$pipelineId/submissions/$submissionId',
    });
  },
});
