import posthog from 'posthog-js';

import type { PipelineTypeFilter } from 'client/types.gen';

type IntroductionStatusType = 'global' | 'pipeline' | 'talent_fyi';

type CaptureFn = ((
  // A new pipeline has been created, i.e. a user confirmed the new pipeline
  // creation by selecting Open on the new pipeline form/Create a new
  // search/Create a new talent FYI pipeline
  event_name: 'open_pipeline',
  properties: {
    pipeline_slug: string;
    // TODO: Jess explain?
    // TODO: when flags['project/collections'] is removed,
    // we no longer need 'pipeline' in this type
    pipeline_type: PipelineTypeFilter | 'pipeline';
    team_slug: string;
  },
) => void) &
  ((
    // A new introduction was requested
    event_name: 'request_introduction',
    properties: {
      candidate_id: string;
      pipeline_slug?: string;
      pipeline_type: IntroductionStatusType;
      requester_id: string;
      team_slug: string;
    },
  ) => void) &
  ((
    // An introduction has been facilitated
    event_name: 'facilitate_introduction',
    properties: {
      candidate_id: string;
      pipeline_slug?: string;
      pipeline_type: IntroductionStatusType;
      requester_id: string;
      team_slug: string;
    },
  ) => void) &
  ((
    // A candidate is added to a Pipeline/Trending
    event_name: 'add_candidate',
    properties: {
      candidate_id: string;
      pipeline_slug?: string;
      pipeline_type: 'pipeline' | 'talent_fyi';
      team_slug: string;
    },
  ) => void) &
  ((
    // User added a candidate to an existing pipeline
    event_name: 'user_add_candidate',
    properties: {
      candidate_id: string;
      pipeline_slug: string;
      pipeline_type: 'pipeline';
      team_slug: string;
      user_id: string;
    },
  ) => void) &
  ((
    event_name: 'share_candidates',
    properties: {
      candidates_id: string[];
      sharer_id: string;
      sharing_method: 'copy' | 'email';
    },
  ) => void) &
  ((
    event_name: 'add_candidate_comment',
    properties: {
      candidate_id: string;
      user_id: string;
    },
  ) => void) &
  ((
    event_name: 'delete_candidate_comment',
    properties: {
      candidate_id: string;
      user_id: string;
    },
  ) => void) &
  ((
    event_name: 'edit_candidate_comment',
    properties: {
      candidate_id: string;
      user_id: string;
    },
  ) => void) &
  ((
    // User added a candidate to favorites
    event_name: 'save_candidate',
    properties: {
      candidate_id: string;
      user_id: string;
    },
  ) => void) &
  ((
    // User removed a candidate from favorites
    event_name: 'unsave_candidate',
    properties: {
      candidate_id: string;
      user_id: string;
    },
  ) => void) &
  ((
    // We manually refreshed the tab because the user left their tab open for
    // too long
    event_name: 'refresh_due_to_stale_client',
    properties: {
      timeout_seconds: number;
      user_id: string;
    },
  ) => void);

const posthogToken = import.meta.env.VITE_APP_POSTHOG_TOKEN;

export const captureOkr: CaptureFn = (event_name, properties) => {
  if (!posthogToken) {
    return;
  }

  posthog.capture(event_name, properties);
};
