import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const resetPasswordSearchSchema = z.object({
  code: z.string().optional().catch(''),
  email: z.string().optional().catch(''),
});

export const Route = createFileRoute(
  '/_layoutAuth/_guest/reset-password/verify/',
)({
  validateSearch: resetPasswordSearchSchema,
});
