import { createFileRoute } from '@tanstack/react-router';

import { getCandidateCommentaryOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/about/',
)({
  loader: async ({ context, params }) => {
    const { queryClient } = context;
    const pCommentary = queryClient.ensureQueryData(
      getCandidateCommentaryOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    await Promise.all([pCommentary]);
  },
});
