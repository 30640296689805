import type {
  GetPeopleCurrentResponse,
  GetTeamsFindResponse,
} from 'client/types.gen';

type Person = GetPeopleCurrentResponse;
type TeamResponse = GetTeamsFindResponse;

export interface Team {
  additional_details: string;
  client_size: TeamClientSize;
  company: Required<NonNullable<Person['roles']>[number]>['company'];
  diverse_candidates: number;
  functional_focus: NonNullable<
    TeamResponse['results'][0]['functional_focus']
  > | null;
  industry_focus: NonNullable<
    TeamResponse['results'][0]['industry_focus']
  > | null;
  slug: string;
  team_name: string;
  total_candidates: number;
  uuid: string;
}

export enum TeamClientSize {
  Both = 'Both Early Stage and Large/Established',
  Early = 'Mostly Early Stage',
  Established = 'Mostly Large/Established',
}

export interface TeamMemberOnBehalfOfFields {
  on_behalf_of_user_email: string | null;
  on_behalf_of_user_image: string | null;
  on_behalf_of_user_name: string | null;
  on_behalf_of_user_uuid: string | null;
}

export interface TeamSearchResult extends Pick<Team, 'slug' | 'team_name'> {
  prior_introduction: boolean;
}

export enum TeamSlug {
  New = '_newTeamSlug',
}

export interface UseSearchTeamsResult {
  isLoadingTeams: boolean;
  onSearchTeams: (value: string) => void;
  resultsTeams: GetTeamsFindResponse;
}
