import { createFileRoute, redirect } from '@tanstack/react-router';

import { getTeamPipelineRecommendationIntroductionOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/pipelines/$pipelineId/introductions/$candidateId/',
)({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;
    const pIntroduction = queryClient.ensureQueryData(
      getTeamPipelineRecommendationIntroductionOptions({
        path: {
          candidate_uuid: params.candidateId,
          pipeline_slug: params.pipelineId,
          team_slug: params.teamId,
        },
      }),
    );
    const [introduction] = await Promise.all([pIntroduction]);
    throw redirect({
      params: {
        pipelineId: params.pipelineId,
        recommendationId: introduction.uuid,
        teamId: params.teamId,
      },
      replace: true,
      to: '/teams/$teamId/pipelines/$pipelineId/recommendations/$recommendationId',
    });
  },
});
