import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { PipelineTypeFilter } from 'client/types.gen';
import { objToEnum } from 'helpers/zod';

export const teamPipelinesSearchSchema = z.object({
  type: z
    .array(z.enum(objToEnum(PipelineTypeFilter)))
    .optional()
    .catch([]),
});

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/pipelines/',
)({
  validateSearch: teamPipelinesSearchSchema,
});
