import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { zFiltersArray } from 'helpers/router';

const trendingPeopleSearchSchema = z.object({
  advisor_id: zFiltersArray(),
  function_expertise: zFiltersArray(),
  industry_expertise: zFiltersArray(),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/trending/people/',
)({
  validateSearch: trendingPeopleSearchSchema,
});
