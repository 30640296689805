import { createFileRoute } from '@tanstack/react-router';
// import { createFileRoute, notFound } from '@tanstack/react-router'

export const Route = createFileRoute('/_private/_route/_internal')({
  /**
   * TODO: This does not work, notFound() does not lead to the expected
   * not found component. Need to figure out how to make it work.
   * @link https://github.com/TanStack/router/pull/1907#issuecomment-2223409745
   */
  // beforeLoad: async ({ context }) => {
  //   const { queryClient } = context;
  //   const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
  //   const [person] = await Promise.all([pUser]);
  //   const internalRole = person.roles?.find((role) => role.title === Role.BANFF_ADMIN)
  //   if (!internalRole) {
  //     throw notFound()
  //   }
  // },
});
