import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { BaseSchema } from 'yup';
import { array, mixed, setLocale, string } from 'yup';

declare type Maybe<T> = T | null | undefined;

declare interface SchemaOptions {
  nullable?: boolean;
}

declare type TEnum<T> = Record<string, T> | Array<Maybe<T>>;

export const useYup = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: {
      oneOf: t('form.error.oneOf'),
      required: t('form.error.required'),
    },
    string: {
      email: t('form.error.email'),
    },
  });
};

export const yupArrayMinLengthSchema = (
  t: TFunction,
  count: number,
  context?: string,
) =>
  array().minLength(count, t('form.error.array.minLength', { context, count }));

export const yupEmailSchema = (t: TFunction) => yupStringShortSchema(t).email();

export const yupMaxLengthCandidateAbout = 1000;

export const yupMaxLengthCandidateHighlight = 600;

export const yupMaxLengthLinkedInUrl = 128;

export const yupMaxLengthStringLong = 512;

export const yupMaxLengthStringShort = 64;

export const yupMaxLengthTeamFunctionalFocus = 250;

export const yupMaxLengthTeamIndustryFocus = 250;

export const yupMinLengthPassword = 14;

export const yupOneOfSchema = <T extends string | null>(
  t: TFunction,
  enums: TEnum<T>,
  options?: SchemaOptions,
): BaseSchema<T> => {
  const opts: SchemaOptions = {
    nullable: options?.nullable ?? false,
  };
  const enumsArray = Array.isArray(enums) ? enums : Object.values(enums);
  const arrayOfValues = opts.nullable ? [...enumsArray, null] : enumsArray;
  return mixed<T>().oneOf(arrayOfValues) as BaseSchema<T>;
};

export const yupPasswordUppercaseRegex = /^(?=.*[A-Z])/;
export const yupPasswordSymbolRegex = /^(?=.*[@$!%*?&()=_])/;
export const yupPasswordNumberRegex = /^(?=.*[0-9])/;
export const yupPasswordLowercaseRegex = /^(?=.*[a-z])/;
export const yupPasswordSchema = (t: TFunction) =>
  string()
    .min(
      yupMinLengthPassword,
      t('form.error.password.minLength', { count: yupMinLengthPassword }),
    )
    .matches(yupPasswordUppercaseRegex, t('form.error.password.matchUpperCase'))
    .matches(yupPasswordLowercaseRegex, t('form.error.password.matchLowerCase'))
    .matches(yupPasswordNumberRegex, t('form.error.password.matchNumber'))
    .matches(yupPasswordSymbolRegex, t('form.error.password.matchSymbol'));

export const yupPostalCodeSchema = (t: TFunction) =>
  yupStringShortSchema(t).matches(
    /^[0-9-]+$/,
    t('form.error.postalCodePattern'),
  );

export const yupStringLongSchema = (t: TFunction) =>
  yupStringMaxLengthSchema(t, yupMaxLengthStringLong);

export const yupStringMaxLengthSchema = (t: TFunction, count: number) =>
  string().max(count, t('form.error.string.maxLength', { count }));

export const yupStringShortSchema = (t: TFunction) =>
  yupStringMaxLengthSchema(t, yupMaxLengthStringShort);
