import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/_internal/teams/$teamId/searches/$searchId/submissions/$submissionId/',
)({
  beforeLoad: ({ params, search }) => {
    throw redirect({
      params: {
        pipelineId: `s-${params.searchId}`,
        submissionId: params.submissionId,
        teamId: params.teamId,
      },
      replace: true,
      search,
      to: '/teams/$teamId/pipelines/$pipelineId/submissions/$submissionId',
    });
  },
});
