import { useTranslation } from 'react-i18next';

import { Center, centerProps, Icon, Text, Wrapper } from './styled';
import type { Props } from './types';

import { AntIconLoading } from 'components/UI/Icons';

export function Loading({
  className,
  hideText,
  size,
  testId,
  ...rest
}: Props & { testId?: string }) {
  const { t } = useTranslation();

  const isLarge = size === 'large';

  return (
    <Wrapper isLarge={isLarge} {...rest}>
      <Icon data-test={testId} isLarge={isLarge}>
        <AntIconLoading />
      </Icon>
      {!hideText && <Text isLarge={isLarge}>{t('loading.text')}</Text>}
    </Wrapper>
  );
}

Loading.Center = Center;
Loading.centerProps = centerProps;
