import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { postCandidateFeedbackFilterOptions } from 'client/@tanstack/react-query.gen';

const schema = z.object({
  page: z.coerce.number().optional().default(1).catch(1),
  results: z.coerce.number().optional().default(10).catch(10),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/feedback/',
)({
  loaderDeps: ({ search: { page, results } }) => ({
    page,
    results,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loader: async ({ context, deps, params }) => {
    const { queryClient } = context;
    const pFeedback = queryClient.ensureQueryData(
      postCandidateFeedbackFilterOptions({
        body: {
          page: deps.page,
          page_size: deps.results,
        },
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    await Promise.all([pFeedback]);
  },
  validateSearch: schema,
});
