import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/searches/$searchId/',
)({
  beforeLoad: ({ params, search }) => {
    throw redirect({
      params: {
        pipelineId: `s-${params.searchId}`,
        teamId: params.teamId,
      },
      replace: true,
      search,
      to: '/teams/$teamId/pipelines/$pipelineId',
    });
  },
});
