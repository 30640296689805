import { useEffect } from 'react';

import { useSyncAuthStateBetweenTabs } from 'helpers/auth';
import { setUser } from 'helpers/logging';
import { useQueryPeopleCurrent } from 'helpers/query';

export function AxiosAuthSubscriber() {
  // TODO: replace with router context
  const queryPerson = useQueryPeopleCurrent();

  useSyncAuthStateBetweenTabs();

  useEffect(() => {
    if (queryPerson.isLoading) {
      return;
    }
    setUser(queryPerson.data);
  }, [queryPerson.data, queryPerson.isLoading]);

  return null;
}
