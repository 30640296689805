import type { GetCompaniesFindResponse } from 'client/types.gen';
import type { PageItem } from 'types/api';

export enum CompanyCustomerType {
  Advisory = 'advisory',
  Investor = 'investor',
  Other = 'other',
  Search = 'search',
}

type TCompany = PageItem<GetCompaniesFindResponse>;

export interface AdminCompany {
  alias: string;
  assets_managed: CompanyAssetsUnderManagementRange | null;
  budget: CompanyBudgetRange | null;
  company_type: CompanyType;
  domain: string;
  employees: CompanyEmployeesRange | null;
  funding_round: CompanyFundingRound | null;
  id: string;
  industry: CompanyIndustry;
  industry_keywords: string | null;
  market_cap: CompanyMarketCapitalizationRange | null;
  name: string;
  payment_status: CompanyPaymentStatus;
  revenue: CompanyRevenueRange | null;
  sector: string;
  slug: string;
  stage: CompanyStage;
  stock_symbol: string | null;
  team_count: number;
  type: TCompany['type'];
  user_count: number;
  valuation: CompanyValuationRange | null;
}

export type Company = Pick<
  AdminCompany,
  'id' | 'name' | 'payment_status' | 'slug' | 'type'
>;

export enum CompanyAssetsUnderManagementRange {
  Over100MUnder1B = '$100M-$1B',
  Over10B = '>$10B',
  Over1BUnder10B = '$1B-$10B',
  Under100M = '<$100M',
}

export enum CompanyBudgetRange {
  Over100KUnder250K = '$100K-$250K',
  Over100M = '>$100M',
  Over10MUnder100M = '$10M-$100M',
  Over1MUnder5M = '$1M-$5M',
  Over250KUnder500K = '$250K-$500K',
  Over500KUnder1M = '$500K-$1M',
  Over5MUnder10M = '$5M-$10M',
  Under100K = '<$100K',
}

export enum CompanyEmployeesRange {
  Over100K = '>100,000',
  Over100Under1K = '101-1,000',
  Over10KUnder50K = '10,001-50,000',
  Over1KUnder5K = '1,001-5,000',
  Over50KUnder100K = '50,001-100,000',
  Over5KUnder10K = '5,001-10,000',
  Under100 = '1-100',
}

export enum CompanyFinancialsMetric {
  Revenue = 'revenue',
  Valuation = 'valuation',
}

export enum CompanyFundingRound {
  SeriesA_UNSTABLE = 'Series A',
  SeriesB_UNSTABLE = 'Series B',
  SeriesC_UNSTABLE = 'Series C',
  SeriesD_UNSTABLE = 'Series D',
  SeriesE_UNSTABLE = 'Series E',
  SeriesFAndMore_UNSTABLE = 'Series F and on',
}

export enum CompanyIndustry {
  BasicMaterials = 'Basic Materials',
  ConsumerDiscretionary = 'Consumer Discretionary',
  ConsumerStaples = 'Consumer Staples',
  Energy = 'Energy',
  Financials = 'Financials',
  HealthCare = 'Health Care',
  Industrials = 'Industrials',
  RealEstate = 'Real Estate',
  Technology = 'Technology',
  Telecommunications = 'Telecommunications',
  Utilities = 'Utilities',
}

export enum CompanyMarketCapitalizationRange {
  Over10BUnder200B = '$10B-$200B',
  Over200B = '>$200B',
  Over2BUnder10B = '$2B-$10B',
  Over300MUnder2B = '$300M-$2B',
  Under300M = '<$300M',
}

export enum CompanyPaymentStatus {
  Free = 'free',
  Premium = 'premium',
}

export enum CompanyRevenueRange {
  Over100MUnder500M = '$100M-$500M',
  Over10MUnder100M = '$10M-$100M',
  Over500M = '>$500M',
  Under10M = '<$10M',
}

export enum CompanyRevenueType {
  AssetsUnderManagement = 'Assets under management',
  Budget = 'Annual budget',
  Revenue = 'Annual revenue',
}

export enum CompanySlug {
  Advisor = '_advisorSlug',
  New = '_newCompanySlug',
}

export enum CompanyStage {
  Early = 'Early Stage',
  Established = 'Established',
  Growth = 'Pre-IPO/Growth',
  Seed = 'Seed',
}

export enum CompanyType {
  Government_UNSTABLE = 'Government',
  Nonprofit = 'Nonprofit',
  Private = 'Private',
  Public = 'Public',
}

export enum CompanyValuationRange {
  Over100MUnder1B = '$100M-$1B',
  Over10MUnder100M = '$10M-$100M',
  Over1B = '>$1B',
  Over1MUnder10M = '$1M-$10M',
  Under1M = '<$1M',
}

export enum CompanyValuationType {
  MarketCapitalization = 'Market Cap',
  Valuation = 'Valuation',
}

export interface UseSearchCompaniesResult {
  isLoadingCompanies: boolean;
  onSearchCompanies: (value: string) => void;
  phraseCompany?: string;
  resultsCompanies: GetCompaniesFindResponse;
}
