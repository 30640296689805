import { Suspense as ReactSuspense, useMemo } from 'react';

import type { LoadingSize } from 'components/Loading/types';
import { getSuspenseFallback } from 'helpers/layout';

export function Suspense({
  fallback,
  ...rest
}: {
  children?: React.ReactNode;
  fallback?: LoadingSize | React.ReactElement;
}) {
  const fallbackComponent = useMemo(() => {
    if (!fallback) {
      return null;
    }

    if (typeof fallback === 'string') {
      return getSuspenseFallback(fallback);
    }

    return fallback;
  }, [fallback]);

  return <ReactSuspense fallback={fallbackComponent} {...rest} />;
}
