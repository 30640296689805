import { createFileRoute, Outlet } from '@tanstack/react-router';

import { getTeamOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute('/_private/_route/teams/$teamId')({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;
    const pTeam = await queryClient.ensureQueryData(
      getTeamOptions({
        path: {
          team_slug: params.teamId,
        },
      }),
    );

    return {
      currentTeam: pTeam,
    };
  },
  component: Outlet,
});
