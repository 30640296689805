import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { getTeamPipelineOptions } from 'client/@tanstack/react-query.gen';

export const editPipelineSearchSchema = z.object({
  from: z.string().optional().catch(undefined),
});

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/pipelines/$pipelineId/edit',
)({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;
    await queryClient.ensureQueryData(
      getTeamPipelineOptions({
        path: {
          pipeline_uuid: params.pipelineId,
          team_uuid: params.teamId,
        },
      }),
    );
  },
  validateSearch: editPipelineSearchSchema,
});
