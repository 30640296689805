import { createFileRoute, redirect } from '@tanstack/react-router';

import { Role } from 'client/types.gen';

export const Route = createFileRoute('/_private/')({
  beforeLoad: ({ context, location }) => {
    const { user } = context;
    const role = user.roles?.[0];

    if (!role) {
      // TODO: Handle clients and prospects
      throw new Error('invalid user role');
    }

    if (role.team_slug) {
      throw redirect({
        params: {
          teamId: role.team_slug,
        },
        replace: true,
        search: {
          stats: '30d',
          updates: 'unread',
        },
        to: '/teams/$teamId/home',
      });
    }

    if (role.title === Role.UNASSIGNED_TEAM_MEMBER) {
      throw redirect({
        replace: true,
        to: '/user/teams',
      });
    }

    if (role.title === Role.BANFF_ADMIN) {
      throw redirect({
        replace: true,
        search: location.search,
        to: '/pipelines',
      });
    }
  },
});
