import { createFileRoute, redirect } from '@tanstack/react-router';

import { getSubmissionOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute(
  '/_private/_route/_internal/trending/$submissionId/edit/',
)({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;
    const pSubmission = queryClient.ensureQueryData(
      getSubmissionOptions({
        path: {
          submission_uuid: params.submissionId,
        },
      }),
    );
    const [submission] = await Promise.all([pSubmission]);
    if (submission.status === 'published') {
      throw redirect({
        params: {
          submissionId: params.submissionId,
        },
        replace: true,
        to: '/trending/$submissionId',
      });
    }
  },
});
