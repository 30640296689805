import { useEffect, useRef, useState } from 'react';

export const useEffectAfterRender = (
  body: VoidFunction,
  deps: any[],
  onRender?: VoidFunction,
) => {
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;

      if (onRender) {
        onRender();
      }

      return;
    }

    body();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

// Ensures our ref says up-to-date with the latest value.
export const useRefValue = <T>(value: T) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export const useKeyDown = (key: 'command' | 'ctrl') => {
  const [isKeyDown, setKeyDown] = useState(false);

  useEffect(() => {
    function downHandler(event: KeyboardEvent) {
      if (key === 'command' && event.metaKey) {
        setKeyDown(true);
      } else if (key === 'ctrl' && event.ctrlKey) {
        setKeyDown(true);
      }
    }

    function upHandler() {
      setKeyDown(false);
    }

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [key]);

  return isKeyDown;
};
