import { createFileRoute, redirect } from '@tanstack/react-router';

import { getPeopleCurrentOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute('/_private/_auth/signup/')({
  beforeLoad: async ({ context }) => {
    const { queryClient } = context;
    const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
    const [person] = await Promise.all([pUser]);
    if (!person.newly_invited) {
      throw redirect({ to: '/' });
    }
  },
});
