import { createFileRoute } from '@tanstack/react-router';

import { getCandidateV2Options } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId',
)({
  loader: async ({ context, params }) => {
    const { queryClient } = context;
    const pCandidate = queryClient.ensureQueryData(
      getCandidateV2Options({
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    await Promise.all([pCandidate]);
  },
});
