import { Typography as AntTypography } from 'antd';
import { useCallback } from 'react';
import { Box } from 'theme-ui';

import type { Props } from './types';

export const Text = ({ ...rest }: Props) => {
  // @ts-ignore
  const as = useCallback((props) => <AntTypography.Text {...props} />, []);

  return <Box as={as} {...rest} />;
};
