import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/searches/$searchId/candidates/$candidateId/',
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      params: {
        candidateId: params.candidateId,
        pipelineId: `s-${params.searchId}`,
        teamId: params.teamId,
      },
      replace: true,
      to: '/teams/$teamId/pipelines/$pipelineId/introductions/$candidateId',
    });
  },
});
