import { createFileRoute, redirect } from '@tanstack/react-router';

import { getPeopleCurrentOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute('/_private/_route')({
  beforeLoad: async ({ context }) => {
    const { queryClient } = context;
    const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
    const [person] = await Promise.all([pUser]);

    if (person.newly_invited) {
      throw redirect({ replace: true, to: '/signup' });
    }

    if (person.has_forgotten_password) {
      throw redirect({ replace: true, to: '/set-password' });
    }
  },
});
