import { createContext } from 'react';

interface TItemContext {
  i18nKey?: string;
  id: string;
  label?: string;
  required?: boolean;
}

export const I18nContext_DEPRECATED = createContext('');
export const ItemContext = createContext<TItemContext>({
  id: '',
});
