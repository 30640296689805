import { Typography as AntTypography } from 'antd';
import type { TypographyProps as AntTypographyProps } from 'antd/lib/typography/Typography';

import { Text } from './Text';
import { Title } from './Title';

// @ts-ignore Ant Design
export function Typography(props: AntTypographyProps) {
  return <AntTypography {...props} />;
}

Typography.Text = Text;
Typography.Title = Title;
