import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const loginSearchSchema = z.object({
  continue: z.string().optional().catch(''),
  // prompt: 'existing-account' | string
  prompt: z.string().optional().catch(''),
});

export const Route = createFileRoute('/_layoutAuth/_guest/login/')({
  validateSearch: loginSearchSchema,
});
