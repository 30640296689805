import * as Sentry from '@sentry/react';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { QueryClientProvider } from '@tanstack/react-query';
import { theme as antTheme, ConfigProvider } from 'antd';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useToaster } from 'react-hot-toast/headless';
import type { RecoilRootProps } from 'recoil';
import { RecoilRoot } from 'recoil';
import { ThemeUIProvider } from 'theme-ui';

import { LayoutFallback } from 'components/LayoutFallback';
import { Suspense } from 'components/Suspense';
import { queryClient } from 'helpers/query';
import { lazy } from 'helpers/react';
import { theme } from 'types/theme';

const ErrorPage = lazy(() => import('pages/Error'));

broadcastQueryClient({
  broadcastChannel: 'contender',
  queryClient,
});

export function AppProvider({
  children,
  initializeState,
}: Exclude<RecoilRootProps, { override: false }>) {
  const { handlers, toasts } = useToaster();
  const { calculateOffset, endPause, startPause, updateHeight } = handlers;

  return (
    <Suspense>
      {/* @ts-ignore Theme UI */}
      <ThemeUIProvider theme={theme}>
        <ConfigProvider
          theme={{
            algorithm: antTheme.defaultAlgorithm,
            token: {
              blue: theme.colors['blue-500'],
              colorBgLayout: '#fff',
              colorError: theme.colors['red-5'],
              colorInfo: theme.colors['ba-green-6'],
              colorInfoBg: theme.colors['ba-green-1'],
              colorInfoBorder: theme.colors['ba-green-3'],
              colorLink: theme.colors['ba-green-6'],
              colorLinkHover: theme.colors['ba-green-5'],
              colorPrimary: theme.colors['ba-green-6'],
              colorTextBase: theme.colors['gray-9'],
              fontFamily: theme.fonts.default,
              geekblue: theme.colors['geekblue-6'],
              gold: theme.colors['gold-6'],
              green: theme.colors['green-6'],
              lime: theme.colors['lime-6'],
              red: theme.colors['red-6'],
              screenLG: Number.parseInt(theme.mediaQueries.lg, 10),
              screenSM: Number.parseInt(theme.mediaQueries.sm, 10),
              screenXL: Number.parseInt(theme.mediaQueries.xl, 10),
              screenXS: Number.parseInt(theme.mediaQueries.xs, 10),
              screenXXL: Number.parseInt(theme.mediaQueries.xxl, 10),
              volcano: theme.colors['volcano-6'],
            },
          }}
        >
          <RecoilRoot initializeState={initializeState}>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<LayoutFallback />}>
                <Sentry.ErrorBoundary fallback={<ErrorPage />}>
                  <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                  <div
                    className="fixed bottom-2 right-2 z-20"
                    onMouseEnter={startPause}
                    onMouseLeave={endPause}
                  >
                    {toasts.map((x) => {
                      const offset = calculateOffset(x, {
                        gutter: 8,
                        reverseOrder: false,
                      });

                      const ref = (el: HTMLDivElement | null) => {
                        if (el && typeof x.height !== 'number') {
                          const height = el.getBoundingClientRect().height;
                          updateHeight(x.id, height);
                        }
                      };

                      return (
                        <div
                          key={x.id}
                          ref={ref}
                          className={cn(
                            'absolute bottom-0 right-0 z-20 min-w-max rounded-[30px] border border-solid border-gray-900 bg-gray-800 px-4 py-3 text-14/120% text-white transition-all duration-500 ease-out',
                            {
                              'opacity-0': !x.visible,
                              'opacity-100': x.visible,
                            },
                          )}
                          style={{
                            transform: `translateY(-${offset}px)`,
                          }}
                          {...x.ariaProps}
                        >
                          {x.message as string}
                        </div>
                      );
                    })}
                  </div>
                </Sentry.ErrorBoundary>
              </Suspense>
            </QueryClientProvider>
          </RecoilRoot>
        </ConfigProvider>
      </ThemeUIProvider>
    </Suspense>
  );
}
