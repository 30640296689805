import { infiniteQueryOptions, keepPreviousData } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { DateTime } from 'luxon';
import { z } from 'zod';

import {
  getHomeTeamActivityInfiniteOptions,
  getHomeTeamCandidatesOptions,
  getHomeTeamStatsOptions,
  getPeopleCurrentOptions,
} from 'client/@tanstack/react-query.gen';
import { HomeTeamActivityType } from 'client/types.gen';
import { isInternalPerson } from 'helpers/person';
import { objToEnum } from 'helpers/zod';

const schema = z.object({
  stats: z
    .union([
      z.literal('30d'),
      z.literal('3mo'),
      z.literal('ytd'),
      z.literal('all'),
    ])
    .optional()
    .default('30d')
    .catch('30d'),
  updates: z
    .enum(objToEnum(HomeTeamActivityType))
    .optional()
    .default('unread')
    .catch('unread'),
});

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute('/_private/_route/teams/$teamId/home/')({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;
    const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
    const [person] = await Promise.all([pUser]);
    const isInternal = isInternalPerson(person);
    if (isInternal) {
      throw redirect({
        params: {
          teamId: params.teamId,
        },
        to: '/teams/$teamId/pipelines',
      });
    }
  },
  loaderDeps: ({ search: { stats, updates } }) => ({
    stats,
    updates,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loader: async ({ context, deps, params }) => {
    const { queryClient } = context;
    const dates = getStatsDates({ stats: deps.stats });
    const pActivity = new Promise<void>((resolve, reject) => {
      const fetchPage = async () => {
        try {
          const infiniteOptions = infiniteQueryOptions({
            ...getHomeTeamActivityInfiniteOptions({
              path: {
                team_uuid: params.teamId,
              },
              query: {
                size: 10,
                type: deps.updates,
              },
            }),
            getNextPageParam: (lastPage) =>
              lastPage.page < lastPage.page_count ? lastPage.page + 1 : null,
            initialPageParam: 1,
            placeholderData: keepPreviousData,
          });

          /**
           * `ensureQueryData()` implementation for infinite queries
           * @link https://github.com/TanStack/query/discussions/6712#discussioncomment-8137683
           */
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const result =
            queryClient.getQueryData(infiniteOptions.queryKey) ??
            (await queryClient.fetchInfiniteQuery(infiniteOptions));
          resolve(undefined);
        } catch (error) {
          reject(error);
        }
      };

      fetchPage();
    });
    const pCandidates = queryClient.ensureQueryData(
      getHomeTeamCandidatesOptions({
        path: {
          team_uuid: params.teamId,
        },
      }),
    );
    const pStats = queryClient.ensureQueryData(
      getHomeTeamStatsOptions({
        path: {
          team_uuid: params.teamId,
        },
        query: {
          date_from: dates.from?.toISO() ?? undefined,
          date_to: dates.to?.toISO() ?? undefined,
        },
      }),
    );
    await Promise.all([pActivity, pCandidates, pStats]);
  },
  validateSearch: schema,
});

export const getStatsDates = ({
  stats,
}: Pick<SearchSchema, 'stats'>): {
  from?: DateTime;
  to?: DateTime;
} => {
  const now = DateTime.fromISO(new Date().toISOString(), {
    zone: 'utc',
  }).startOf('minute');
  switch (stats) {
    case '30d':
      return {
        from: now.minus({ months: 1 }),
        to: undefined,
      };
    case '3mo':
      return {
        from: now.minus({ months: 3 }),
        to: undefined,
      };
    case 'ytd':
      return {
        from: now.startOf('year'),
        to: undefined,
      };
    case 'all':
    default:
      return {
        from: undefined,
        to: undefined,
      };
  }
};
