import type { BoxProps } from 'theme-ui';
import { Box, Flex } from 'theme-ui';

import { Typography } from '../Typography';

import type { IconProps, TextProps, WrapperProps } from './types';

export const centerProps = {
  padding: 'grid-40',
  sx: {
    alignItems: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
  },
};

export const Center = ({ sx, ...rest }: BoxProps) => (
  <Flex
    {...centerProps}
    sx={{
      ...centerProps.sx,
      ...sx,
    }}
    {...rest}
  />
);

export const Icon = ({ isLarge, sx, ...rest }: IconProps) => (
  <Box
    sx={{
      '.anticon': {
        display: 'block',
      },
      fontSize: isLarge ? '48px' : '24px',
      ...sx,
    }}
    {...rest}
  />
);

export const Text = ({ isLarge, ...rest }: TextProps) => (
  <Typography.Text
    color="inherit"
    paddingLeft={isLarge ? 0 : 'grid-10'}
    paddingTop={isLarge ? 'grid-8' : 0}
    variant={isLarge ? 'textStyles.regular-16/24' : 'textStyles.regular-14/22'}
    {...rest}
  />
);

export const Wrapper = ({ isLarge, sx, ...rest }: WrapperProps) => (
  <Flex
    color="ba-green-6"
    sx={{
      alignItems: 'center',
      flexDirection: isLarge ? 'column' : 'row',
      ...sx,
    }}
    {...rest}
  />
);
