import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/teams/$teamId/')({
  beforeLoad: ({ params }) => {
    throw redirect({
      params: {
        teamId: params.teamId,
      },
      replace: true,
      search: {
        stats: '30d',
        updates: 'unread',
      },
      to: '/teams/$teamId/home',
    });
  },
});
