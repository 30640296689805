import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// to automatically detect browser language, install the
// `i18next-browser-languagedetector` package
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    lng: 'en',
    react: {
      transSupportBasicHtmlNodes: true,
    },
    returnNull: false,
  });
