import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { getPeopleCurrentOptions } from 'client/@tanstack/react-query.gen';
import { PipelineTypeFilter, Role } from 'client/types.gen';
import { zFiltersArray } from 'helpers/router';
import { objToEnum } from 'helpers/zod';

export const newPipelineSearchSchema = z.object({
  from: z.string().optional().catch(undefined),
  function_expertise: zFiltersArray(),
  industry_expertise: zFiltersArray(),
  point_of_contact_person_id: z.string().optional().catch(undefined),
  type: z.enum(objToEnum(PipelineTypeFilter)).optional().catch(undefined),
});

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/pipelines/new',
)({
  beforeLoad: async ({ context, params, search }) => {
    const { queryClient } = context;
    const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
    const [person] = await Promise.all([pUser]);
    const internalRole = person.roles?.find(
      (role) => role.title === Role.BANFF_ADMIN,
    );
    if (internalRole) {
      throw redirect({
        search: { ...search, team_slug: params.teamId },
        to: '/pipelines/new',
      });
    }
  },
  validateSearch: newPipelineSearchSchema,
});
