import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/',
)({
  beforeLoad: ({ params, search }) => {
    switch (search.tab) {
      case 'commentary':
        throw redirect({
          params: {
            candidateId: params.candidateId,
          },
          replace: true,
          to: '/candidates/$candidateId/about',
        });
      case 'feedback':
        throw redirect({
          params: {
            candidateId: params.candidateId,
          },
          replace: true,
          search: {
            page: 1,
            results: 10,
          },
          to: '/candidates/$candidateId/feedback',
        });
      case 'recommendations':
        throw redirect({
          params: {
            candidateId: params.candidateId,
          },
          replace: true,
          search: {
            page: 1,
            period: '3mo',
            results: 10,
            statsIntros: 'requests',
            statsType: 'submission',
            tab: 'all',
          },
          to: '/candidates/$candidateId/recommendations',
        });
      case 'offlimits':
      case 'profile':
      default:
        throw redirect({
          params: {
            candidateId: params.candidateId,
          },
          replace: true,
          to: '/candidates/$candidateId/profile',
        });
    }
  },
});
