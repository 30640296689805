import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import {
  ConnectionStatus,
  Function,
  Industry,
  InterestRole,
  OperatingHighlights,
} from 'client/types.gen';
import { objToEnum } from 'helpers/zod';

const schema = z.object({
  connection_status: z
    .array(z.enum(objToEnum(ConnectionStatus)))
    .optional()
    .catch([]),
  filtersDialog: z
    .union([
      z.literal('industry_expertise'),
      z.literal('function_expertise'),
      z.literal('operating_highlights'),
      z.literal('interest_role_operating'),
      z.literal('interest_role_board_advisory'),
      z.literal('connection_status'),
    ])
    .optional()
    .catch(undefined),
  function_expertise: z
    .array(z.enum(objToEnum(Function)))
    .optional()
    .catch([]),
  industry_expertise: z
    .array(z.enum(objToEnum(Industry)))
    .optional()
    .catch([]),
  interest_role_board_advisory: z
    .array(z.enum(objToEnum(InterestRole)))
    .optional()
    .catch([]),
  interest_role_operating: z
    .array(z.enum(objToEnum(InterestRole)))
    .optional()
    .catch([]),
  operating_highlights: z
    .array(z.enum(objToEnum(OperatingHighlights)))
    .optional()
    .catch([]),
});

export type Schema = z.infer<typeof schema>;

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/candidates',
)({
  validateSearch: schema,
});
