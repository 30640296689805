import queryString, { type StringifyOptions } from 'query-string';
import type { Params } from 'react-router-dom';
import { generatePath } from 'react-router-dom';

import type { SearchParams } from 'config/router';

export * from './hooks';
export * from './searchParams';

export { generateAppPath as generatePath };

interface RouteParams {
  candidateId?: string; // uuid
  companyId?: string; // slug
  introductionId?: string; // uuid
  personId?: string; // uuid
  pipelineId?: string; // slug or uuid
  recommendationId?: string; // uuid
  searchId?: string; // slug
  submissionId?: string; // uuid
  tabId?: string; // selected tab
  teamId?: string; // slug or uuid
}

const generateAppPath = (
  pattern: string,
  params?: RouteParams,
  searchParams?: SearchParams | string,
): string =>
  generatePath(pattern, params as Params) + generateSearch(searchParams);

export const generateSearch = (
  searchParams?: SearchParams | string,
  stringifyOptions?: StringifyOptions,
): string => {
  if (!searchParams) {
    return '';
  }

  if (typeof searchParams === 'string') {
    return searchParams.startsWith('?') ? searchParams : `?${searchParams}`;
  }

  const search = queryString.stringify(searchParams, {
    arrayFormat: 'comma',
    ...stringifyOptions,
  });

  return search ? `?${search}` : '';
};

export const getEmailHref = (url: string, appPath: string): string =>
  decodeURIComponent(url + appPath);

export const getEncodedParams = <T extends Record<string, string | undefined>>(
  params: T,
) =>
  Object.entries(params).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value?.replace(/\{\{/g, '{{encoded_'),
    }),
    {},
  ) as unknown as T;

export const getWebHref = (appPath: string): string => {
  const url = window.location.origin;
  return decodeURIComponent(url + appPath);
};

/**
 * Web app path constructors
 */
type PageCandidateTabs =
  | 'commentary'
  | 'feedback'
  | 'history'
  | 'introductions'
  | 'offlimits'
  | 'profile'
  | 'recommendations';
export const getCandidatePath = (
  params: {
    candidateId: string;
  },
  searchParams?: SearchParams<{ tab: PageCandidateTabs }>,
) => generateAppPath('/candidates/:candidateId', params, searchParams);

export const getCandidateSubmissionPath_DEPRECATED = (params: {
  submissionId: string;
}) => generateAppPath('/introduction-submissions/:submissionId', params);

export const getCompanySettingsPath = (params: { companyId: string }) =>
  generateAppPath('/companies/:companyId/settings', params);

export const getPipelinesPath = (
  searchParams?: SearchParams<{ tab: 'submissions' | 'table' }> | string,
) => generateAppPath('/pipelines', undefined, searchParams);

export const getTeamHomePath = (params: { teamId: string }) =>
  generateAppPath('/teams/:teamId/home', params);

export const getTeamIntroductionPath_DEPRECATED = (
  params: {
    candidateId: string;
    teamId: string;
  },
  searchParams?: SearchParams,
) =>
  generateAppPath(
    '/teams/:teamId/introductions/:candidateId',
    params,
    searchParams,
  );

type TabsLayoutStack = 'stack' | 'table';

export const getTeamPipelinePath = (
  params: {
    pipelineId: string;
    teamId: string;
  },
  searchParams?: SearchParams<{ tab: TabsLayoutStack }>,
) =>
  generateAppPath('/teams/:teamId/pipelines/:pipelineId', params, searchParams);

export const getTeamPipelineRecommendationV2Path = (params: {
  pipelineId: string;
  recommendationId: string;
  teamId: string;
}) =>
  generateAppPath(
    '/teams/:teamId/pipelines/:pipelineId/recommendations/:recommendationId',
    params,
  );

export const getTeamPipelineSubmissionPath = (params: {
  pipelineId: string;
  submissionId: string;
  teamId: string;
}) =>
  generateAppPath(
    '/teams/:teamId/pipelines/:pipelineId/submissions/:submissionId',
    params,
  );

export const getTeamSettingsPath = (params: { teamId: string }) =>
  generateAppPath('/teams/:teamId/settings', params);

export const getTeamTrendingRecommendationPath = (
  params: {
    recommendationId: string;
    teamId: string;
  },
  searchParams?: SearchParams | string,
) =>
  generateAppPath(
    '/teams/:teamId/trending/:recommendationId',
    params,
    searchParams,
  );

export const getTrendingSubmissionPath = (
  params: {
    submissionId: string;
  },
  searchParams?: SearchParams,
) => generateAppPath('/trending/:submissionId', params, searchParams);
