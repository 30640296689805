import type { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import type { PageItem, PaginationResult } from 'types/api';
import type {
  UseSearchOptions,
  UseSearchResponse_DEPRECATED,
} from 'types/search';

export const useQueryData = <
  T extends PaginationResult = PaginationResult,
  Item = PageItem<T>,
>({
  defaultResults,
  force,
  phrase,
  predicate,
  query,
  value,
}: Pick<UseSearchOptions<T>, 'defaultResults' | 'value'> & {
  force?: boolean;
  phrase: string;
  predicate: (
    item: Item,
    value: Item[keyof Item],
  ) => Item[keyof Item] | boolean;
  query: UseQueryResult<T>;
}) => {
  const [selectedResult, setSelectedResult] = useState<Item | null>(null);

  const emptyArray: PaginationResult = useMemo(
    () => ({
      page: 1,
      page_count: 0,
      results: [],
      total_counts: {
        all: 0,
      },
    }),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const condition = useMemo(() => predicate, []);

  const getResults = useCallback(
    (data?: T): T => {
      const results = force || phrase ? data : defaultResults;
      return results ?? (emptyArray as T);
    },
    [defaultResults, emptyArray, force, phrase],
  );

  const data = getResults(query.data);

  useEffect(() => {
    if (!value) {
      setSelectedResult(null);
      return;
    }

    const result = data.results.find((x) => {
      // Originally, condition returned an object key value and we compared
      // the passed value against it to determine whether the current item
      // is the selected result. Later, support was added to do the comparison
      // directly inside condition. We would want to do this when we need to
      // check the value against multiple item keys.
      const keyOrBool = condition(x as Item, value as Item[keyof Item]);
      if (typeof keyOrBool === 'boolean') {
        return keyOrBool;
      }
      return keyOrBool === (value as unknown as Item[keyof Item]);
    });
    setSelectedResult((result as Item) ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, value]);

  return {
    results: data,
    selectedResult,
  };
};

export const useSearch = <
  T extends object[] = object[],
  Item extends object = T[number],
>(options?: {
  force?: boolean;
  onSearch?: (phrase: string) => void;
}) => {
  const [debouncedPhrase, setDebouncedPhrase] = useState('');
  const [phrase, setPhrase] = useState('');

  useDebounce(() => setDebouncedPhrase(phrase), 300, [phrase]);

  const onSearch: UseSearchResponse_DEPRECATED<T, Item>['onSearch'] =
    useCallback(
      (event) => {
        setPhrase(typeof event === 'string' ? event : event.target.value);

        options?.onSearch?.(
          typeof event === 'string' ? event : event.target.value,
        );
      },
      [options],
    );

  const reset = useCallback(() => {
    setPhrase('');
  }, []);

  return {
    debouncedPhrase,
    enabled: options?.force || Boolean(phrase),
    onSearch,
    phrase,
    reset,
  };
};
