import type { AnySchema, ArraySchema } from 'yup';
import { addMethod, array } from 'yup';

addMethod(array, 'minLength', function minLength<
  T extends AnySchema,
>(this: ArraySchema<T>, count: number, message?: string) {
  return this.test('field is required', message ?? '', (value: any) => {
    // TODO: any should be replaced with the correct type
    const fields = (value ?? []) as ({ value: any } | any)[];
    return (
      fields.filter((field) =>
        typeof field === 'object' &&
        field !== null &&
        Object.prototype.hasOwnProperty.call(field, 'value')
          ? field.value
          : field,
      ).length >= count
    );
  });
});
