import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import localforage from 'localforage';
import { useEffect } from 'react';

import { client } from 'client/services.gen';
import type { AccessToken } from 'client/types.gen';
import {
  isAxiosErrorPersonLoggedOut,
  isAxiosErrorRefreshFailed,
} from 'helpers/api';
import { useOnSuccessLogOut } from 'helpers/person';
import { useQueryToken } from 'helpers/query';

export const useSyncAuthStateBetweenTabs = () => {
  const queryClient = useQueryClient();
  const queryToken = useQueryToken();

  const { onSuccessLogOut } = useOnSuccessLogOut();

  useEffect(() => {
    const onFocus = async () => {
      const value = await localforage.getItem<AccessToken>('atomToken');

      if (value === null && queryToken.data?.access_token) {
        onSuccessLogOut();
      }
    };

    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onSuccessLogOut, queryClient, queryToken.data]);
};

export const useAxiosAuthInterceptor = () => {
  const queryClient = useQueryClient();

  const { onSuccessLogOut } = useOnSuccessLogOut();

  useEffect(() => {
    const authInterceptor = client.instance.interceptors.response.use(
      undefined,
      (error) => {
        try {
          const isAlreadyLoggedOut = isAxiosErrorPersonLoggedOut(error);
          const isRefreshFailed = isAxiosErrorRefreshFailed(error);

          const data =
            error.response?.config.data &&
            new URLSearchParams(error.response?.config.data);
          const isTokenRefreshFail =
            error.response?.config.url === '/api/v1/token' &&
            error.response?.config.method === 'post' &&
            data?.get('grant_type') === 'refresh_token';
          const isUserLoadFail =
            error.response?.config.url === '/api/v1/people/current' &&
            error.response?.config.method === 'get' &&
            error.response?.status === 404;

          if (
            isTokenRefreshFail ||
            isUserLoadFail ||
            isAlreadyLoggedOut ||
            isRefreshFailed
          ) {
            onSuccessLogOut();
          }
        } catch (e) {
          Sentry.captureException(e);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      client.instance.interceptors.response.eject(authInterceptor);
    };
  }, [onSuccessLogOut, queryClient]);
};
