import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/_internal/introduction-submissions/$submissionId/',
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      params: {
        submissionId: params.submissionId,
      },
      replace: true,
      to: '/trending/$submissionId',
    });
  },
});
