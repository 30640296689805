import { lazy as lazyReact } from 'react';

type LazyComponent = Promise<{ default: React.ComponentType<any> }>;

// wrapper around lazy to handle missing chunk error. Won't be needed after
// migrating to Next.js. Unsure this is needed with Vite, kept just in case.
// learn more: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export const lazy = (dynamicImport: () => LazyComponent) =>
  lazyReact(async (): LazyComponent => {
    const pageHasAlreadyBeenForceRefreshed: boolean = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await dynamicImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        window.location.reload();
        return new Promise(() => () => null);
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
