import type { CandidateShareResponse } from 'client/types.gen';
import type { PageState } from 'types/context';
import type { IForms } from 'types/form';
import type { IModals } from 'types/modal';

type AtomForm = IForms | undefined;

type AtomModal = IModals | undefined;

type AtomPageState = PageState | undefined;

type AtomRecommendations = Record<string, CandidateShareResponse>;

export enum Atoms {
  Form = 'atomForm',
  Modal = 'atomModal',
  PageState = 'atomPageState',
  Recommendations = 'atomRecommendations',
}

export type ClientState = Partial<{
  [Atoms.Form]: AtomForm;
  [Atoms.Modal]: AtomModal;
  [Atoms.PageState]: AtomPageState;
}> & {
  [Atoms.Recommendations]: AtomRecommendations;
};
