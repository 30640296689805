import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/_internal/search-submissions/',
)({
  beforeLoad: () => {
    throw redirect({
      replace: true,
      search: {
        tab: 'submissions',
      },
      to: '/pipelines',
    });
  },
});
