import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/_route/teams/$teamId/network')({
  beforeLoad: ({ params, search }) => {
    if (search.candidate) {
      throw redirect({
        params: {
          candidateId: search.candidate,
          teamId: params.teamId,
        },
        replace: true,
        to: '/teams/$teamId/candidates/$candidateId',
      });
    }

    throw redirect({
      params: {
        teamId: params.teamId,
      },
      replace: true,
      to: '/teams/$teamId/candidates',
    });
  },
});
