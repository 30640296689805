import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { zFiltersArray } from 'helpers/router';

const trendingCampaignsSearchSchema = z.object({
  executive_id: zFiltersArray(),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/trending/campaigns/',
)({
  validateSearch: trendingCampaignsSearchSchema,
});
