import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/searches/',
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      params: {
        teamId: params.teamId,
      },
      replace: true,
      to: '/teams/$teamId/pipelines',
    });
  },
});
