import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import localforage from 'localforage';

import type { AccessToken } from 'client/types.gen';

export const Route = createFileRoute('/_layoutAuth/_guest')({
  beforeLoad: async ({ search }) => {
    const token = await localforage.getItem<AccessToken>('atomToken');
    if (token) {
      const continuePath: string | undefined =
        'continue' in search ? (search.continue as string) : undefined;
      throw redirect({ replace: true, to: continuePath ?? '/' });
    }
  },
  component: Outlet,
});
