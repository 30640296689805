import { Typography as AntTypography } from 'antd';
import { useCallback } from 'react';
import { Box } from 'theme-ui';

import { sxImportant } from '../../../helpers/styles';

import type { Props } from './types';

export const Title = ({
  level,
  sx,
  testId,
  ...rest
}: Props & { testId?: string }) => {
  const as = useCallback(
    // @ts-ignore
    (props) => <AntTypography.Title level={level} {...props} />,
    [level],
  );

  return (
    <Box
      as={as}
      data-test={testId}
      sx={{
        marginBottom: sxImportant(0) as unknown as string,
        ...sx,
      }}
      {...rest}
    />
  );
};
